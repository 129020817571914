<template>
  <div class="row">
    <div class="col-lg-12">
      <el-alert :closable="false" class="mb-3 col-12" title="💡 Info" type="success">
        <p><b>CSAT Settings</b> is where you set if you want to send customer a survey related to their ticket. We will send a message to your customer to do a survey when a ticket has been marked as Resolved</p>
      </el-alert>
      <el-form label-width="180px" label-position="left">
        <el-form-item>
          <span class="font-weight-bold" slot="label">Enable CSAT</span>
          <el-switch v-model="csat.is_enabled" active-color="#128c7f" />
        </el-form-item>
        <el-form-item v-if="csat.is_enabled">
          <span class="font-weight-bold" slot="label">Message</span>
          <el-input show-word-limit maxlength="1000" placeholder="Example: Terima kasih telah mempercayai WAPPIN. Untuk membantu meningkatkan layanan, mohon bantuannya memberikan penilaian. Silakan berikan penilaian melalui tautan dibawah ini." type="textarea" rows="6" v-model="csat.message_content" />
          <b-form-invalid-feedback :state="!v$.csat.message_content.$invalid" v-if="v$.csat.message_content.$errors[0]">
          {{ $t('validation.' + v$.csat.message_content.$errors[0].$validator) }}
          </b-form-invalid-feedback>
        </el-form-item>
        <el-form-item v-if="csat.is_enabled">
          <span class="font-weight-bold" slot="label">Button text</span>
          <el-input type="text" show-word-limit maxlength="20" v-model="csat.button_text"/>
        </el-form-item>
      </el-form>
      <div align="right">
        <el-button type="primary" :disabled="v$.csat.$error" @click="updateCsat" :loading="loading.update_csat" size="small" align="right">Update</el-button>
      </div>
    </div>
  </div>
</template>
<script>
// import moment from 'moment';
import { upperFirst } from 'lodash';
import useVuelidate from '@vuelidate/core';
import { requiredIf } from '@vuelidate/validators';

import csat from '../../../api/csat';
import popupErrorMessages from '../../../library/popup-error-messages';

export default {
  name: 'CSATSettings',
  props: {
    workspace_id: {
      type: String,
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      csat: {
        message_content: { required: requiredIf(this.csat.is_enabled) },
      },
    };
  },
  data() {
    return {
      loading: {
        setting: true,
        update_bot: false,
        update_csat: false,
        auto_reminder: false,
      },
      loaderStack: 0,
      loader: null,
      questions: [],
      csat: {
        is_enabled: false,
        message_content: '',
        button_text: '',
      },
      search: '',
    };
  },
  mounted() {
    this.v$.csat.$touch();
  },
  methods: {
    upperCaseFirst(string) {
      return upperFirst(string);
    },
    showLoader() {
      if (this.loaderStack === 0) {
        this.loader = this.$loading.show();
      }
      this.loaderStack += 1;
    },
    hideLoader() {
      this.loaderStack -= 1;
      if (this.loaderStack === 0) {
        this.loader.hide();
      }
    },
    async loadData() {
      this.v$.csat.$touch();
      this.$parent.loading.setting = true;
      const response = await csat.get_setting({ id: this.workspace_id });
      this.hideLoader();
      this.$parent.loading.setting = false;
      await popupErrorMessages(response);
      this.csat.is_enabled = response.data.is_enabled;
      this.csat.message_content = response.data.message_content || '';
      this.csat.button_text = response.data.button_text || '';
    },
    async updateCsat() {
      this.v$.csat.$touch();
      if (this.v$.csat.$error) return;
      this.$parent.showLoader();
      this.$parent.loading.update_csat = true;
      const response = await csat.update_csat({
        id: this.workspace_id,
        data: {
          is_enabled: this.csat.is_enabled,
          message_content: this.csat.message_content,
          button_text: this.csat.button_text,
        },
      });
      this.$parent.hideLoader();
      this.$parent.loading.update_csat = false;
      await popupErrorMessages(response);
      this.$message({
        type: 'success',
        message: this.$t('settings.success.edit'),
      });
      this.loadData();
    },
  },
};
</script>
<style>
.el-switch__label.is-active {
  color: #10b759 !important;
}
.el-date-editor .el-range-separator {
  width: 15%;
}
.el-switch__label {
  color: #BDC3C7;
}
</style>
