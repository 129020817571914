import api from '../library/axios';

export default {
  get_setting({ id }) {
    return api({
      url: `/api/csat-settings/${id}`,
    });
  },
  update_csat({ id, data }) {
    return api({
      method: 'patch',
      url: `/api/csat-settings/${id}`,
      data,
    });
  },
};
