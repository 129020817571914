<template>
  <div class="row">
    <div class="col-lg-12">
      <el-alert :closable="false" class="mb-3 col-12" title="💡 Info" type="success">
        <p><b>Work Hour</b> setting is where you can set time for your workspace when to receive new ticket. The time you enabled and set below will determine when you workspace is actively receive new ticket.</p>
        <br/>
        - You can activate work hour configuration to <b>prevent any chat created as new ticket</b>
        on non-work hour<br>
        - If your agent available only on work hour, set it as <b>Enabled</b> and set your work
        hour<br />
        - If you want to set it as <b>offline for one day, set the time from 00:00 to
          00:00</b><br />
        - If you want to set it as <b>online 24 hours, leave it as Not Enabled</b>
        <!-- - Use <code style="font-size: 12px" v-html="'{{ticket_no}}'"></code> to be replace with <b>ticket number</b> -->
      </el-alert>
      <el-form label-width="180px" label-position="left">
        <el-form-item>
          <span class="font-weight-bold" slot="label">Enable Working Hour</span>
          <el-switch v-model="work_hour.enabled" active-color="#128c7f" />
        </el-form-item>
        <span v-if="work_hour.enabled">
          <el-form-item v-for="(w, index) in work_hour.config" :key="index">
            <span class="font-weight-bold" slot="label">{{ w.day_name }}</span>
            <el-switch v-model="w.enabled" active-color="#128c7f" active-text="Enabled"
              inactive-text="Not enabled" />&nbsp;<br />
            <el-time-picker v-if="w.enabled" size="small" is-range format="HH:mm" v-model="w.model"
              range-separator="To" start-placeholder="Start time" end-placeholder="End time">
            </el-time-picker>
          </el-form-item>
        </span>
        <el-form-item v-if="work_hour.enabled">
          <span class="font-weight-bold" slot="label">Non-work hour message</span>
          <el-input
            placeholder="Example: Sorry, please contact again at our office hour. Thank you."
            type="textarea" rows="6" v-model="work_hour.message" />
        </el-form-item>
      </el-form>
      <div align="right">
        <el-button @click="updateWorkHour" :loading="loading.update_work_hour" size="small"
          class="btn-primary" align="right">Update</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { upperFirst, isEmpty, cloneDeep } from 'lodash';
import workspaces from '../../../api/workspaces';
import popupErrorMessages from '../../../library/popup-error-messages';

export default {
  name: 'WorkHour',
  // metaInfo: {
  //   title: 'WorkHour',
  // },
  props: {
    workspace_id: {
      type: String,
    },
  },
  data() {
    return {
      loading: {
        setting: true,
        update_bot: false,
        update_work_hour: false,
        auto_reminder: false,
      },
      loaderStack: 0,
      loader: null,
      questions: [],
      work_hour: {
        enabled: false,
        config: [],
        message: '',
      },
      search: '',
    };
  },
  mounted() {
    const temp = [];
    /* eslint-disable no-plusplus */
    for (let i = 0; i < 7; i++) {
      temp.push({
        day: i,
        day_name: moment().weekday(i).format('dddd'),
        from: '08:00',
        until: '17:00',
        model: [moment('08:00', 'hh:mm'), moment('17:00', 'hh:mm')],
        enabled: false,
      });
    }
    this.work_hour.config = temp;
  },
  methods: {
    upperCaseFirst(string) {
      return upperFirst(string);
    },
    showLoader() {
      if (this.loaderStack === 0) {
        this.loader = this.$loading.show();
      }
      this.loaderStack += 1;
    },
    hideLoader() {
      this.loaderStack -= 1;
      if (this.loaderStack === 0) {
        this.loader.hide();
      }
    },
    async loadData() {
      this.$parent.loading.setting = true;
      const response = await workspaces.work_hour({ id: this.workspace_id });
      this.hideLoader();
      this.$parent.loading.setting = false;
      await popupErrorMessages(response);
      if (!isEmpty(response.data.workhour?.config)) {
        /* eslint-disable no-param-reassign */
        this.work_hour.config = response.data.workhour?.config.map((v, index) => {
          v.day_name = moment().weekday(index).format('dddd');
          v.model = [moment(v.from, 'hh:mm'), moment(v.until, 'hh:mm')];
          return v;
        });
        this.work_hour.enabled = response.data.workhour.enabled;
        this.work_hour.message = response.data.workhour.message;
      }
    },
    async updateWorkHour() {
      this.$parent.loading.update_work_hour = true;
      let data_update = cloneDeep(this.work_hour.config);
      data_update = data_update.map((v) => {
        v.from = moment(v.model[0]).format('HH:mm');
        v.until = moment(v.model[1]).format('HH:mm');
        delete v.day_name;
        delete v.model;
        return v;
      });
      const response = await workspaces.update_work_hour({
        id: this.workspace_id,
        data: {
          /* eslint-disable no-param-reassign */
          workhour: {
            enabled: this.work_hour.enabled,
            config: data_update,
            message: this.work_hour.message,
          },
        },
      });
      this.hideLoader();
      this.$parent.loading.update_work_hour = false;
      await popupErrorMessages(response);
      this.$message({
        type: 'success',
        message: this.$t('settings.success.edit'),
      });
      this.loadWorkHour();
    },
  },
};
</script>
<style>
.el-switch__label.is-active {
  color: #10b759 !important;
}
.el-date-editor .el-range-separator {
  width: 15%;
}
.el-switch__label {
  color: #BDC3C7;
}
</style>
